<template>
  <div>
    <page-description
      :path="[
        { label: $t('data.sidebar_preferences'), route: 'preferences' },
        { label: 'Gestion des utilisateurs', route: 'users' },
      ]"
    />
    <Preferences v-if="path == ''" />
    
        <!-- { label: 'roles', route: 'roles' },
        { label: 'authorisations', route: 'authorisations' }, -->
    <router-view v-else />
  </div>
</template>

<script>
import Preferences from "./preferences.vue";
export default {
  components: {
    Preferences,
  },
  data: () => ({
    path: "",
  }),
  created() {
    this.getPath();
  },
  mounted() {
    this.getPath();
  },
  updated() {
    this.getPath();
  },
  methods: {
    getPath() {
      this.path = this.$route.path.replace("/parametres", "");
    },
  },
};
</script>

<style></style>
